<template src="./EvaluateResearchGroup.html"></template>

<script>
import groupAnnouncementServices from "../../services/GroupAnnouncement";

export default {
  name: "evaluate-research-group",
  data() {
    return {
      generalAnnouncement: {
        group: {
          name: "",
          description: "",
          category: { name: "" },
          institution: { name: "" },
        },
        groupAnnouncement: {
          announcement: {
            name: "",
            description: "",
            category: { name: "" },
            institution: { name: "" },
            dateInit: "",
            dateFinish: "",
            id: 0,
          },
          endorsement: { url: "", state: false },
          resume: { url: "", state: false },
          formulation: { url: "", state: false },
          groupId: "",
        },
      },
      showViewMore: false,
    };
  },
  created() {
    this.specificGroupAnnouncement();
  },
  methods: {
    async specificGroupAnnouncement() {
      try {
        this.generalAnnouncement =
          await groupAnnouncementServices.getSpecificGroupAnnouncement(
            this.$route.params.id
          );
      } catch (error) {
        console.error("error", error);
      }
    },
    async acceptApplication() {
      try {
        const obj = {
          groupId: this.generalAnnouncement.groupAnnouncement.groupId,
          announcementId:
            this.generalAnnouncement.groupAnnouncement.announcement.id,
          passed: true,
          resume: {
            state: this.generalAnnouncement.groupAnnouncement.resume.state,
            url: this.generalAnnouncement.groupAnnouncement.resume.url,
          },
          endorsement: {
            state: this.generalAnnouncement.groupAnnouncement.endorsement.state,
            url: this.generalAnnouncement.groupAnnouncement.endorsement.url,
          },
          formulation: {
            state: this.generalAnnouncement.groupAnnouncement.formulation.state,
            url: this.generalAnnouncement.groupAnnouncement.formulation.url,
          },
        };
        await groupAnnouncementServices.updateGroupAnnouncement(
          obj,
          this.$route.params.id
        );
        this.$router.push({
          name: "DetailAnnouncement",
          params: {
            id: this.generalAnnouncement.groupAnnouncement.announcement.id,
          },
        });
      } catch (error) {
        console.error("error", error);
      }
    },
    async rejectApplication() {
      try {
        const obj = {
          groupId: this.generalAnnouncement.groupAnnouncement.groupId,
          announcementId:
            this.generalAnnouncement.groupAnnouncement.announcement.id,
          passed: false,
          resume: {
            state: this.generalAnnouncement.groupAnnouncement.resume.state,
            url: this.generalAnnouncement.groupAnnouncement.resume.url,
          },
          endorsement: {
            state: this.generalAnnouncement.groupAnnouncement.endorsement.state,
            url: this.generalAnnouncement.groupAnnouncement.endorsement.url,
          },
          formulation: {
            state: this.generalAnnouncement.groupAnnouncement.formulation.state,
            url: this.generalAnnouncement.groupAnnouncement.formulation.url,
          },
        };
        await groupAnnouncementServices.updateGroupAnnouncement(
          obj,
          this.$route.params.id
        );
        this.$router.push({
          name: "DetailAnnouncement",
          params: {
            id: this.generalAnnouncement.groupAnnouncement.announcement.id,
          },
        });
      } catch (error) {
        console.error("error", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./EvaluateResearchGroup.scss"></style>